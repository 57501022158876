import { AnyAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { storageClient } from '../../../api/client';
import { StorageCreateStorageUploadRequest, StorageUpdateStorageUploadRequest } from '../../../api/schema';
import { getPagination, keysToId } from '../../../helpers/sliceHelper';
import { ListResponseType } from '../../interface';
import { RootState } from '../../store';
import { StorageListUploadQuery, StorageListUploadResponseType, StorageListUploadType, StorageState } from './storageInterface';

export const storageListAdapter = createEntityAdapter<StorageListUploadType>({
  selectId: (storageList) => storageList.id || '',
});

export const storageListUpload = createAsyncThunk('storage/storageListUpload', async (query: StorageListUploadQuery) => {
  const argumentsKeys = ['fileKey', 'ownerId', 'offset', 'limit', 'options'];
  return storageClient.storageListUpload.apply(
    this,
    argumentsKeys.map((key) => query[key]) as [fileKey?: string, ownerId?: string, offset?: number, limit?: number, options?: Record<string, unknown>],
  );
});

export const storageCreateUpload = createAsyncThunk('storage/storageCreateUpload', async (query: StorageCreateStorageUploadRequest) =>
  storageClient.storageCreateUpload(query),
);
export const storageUpdateUpload = createAsyncThunk(
  'storage/storageUpdateUpload',
  async ({ uuid, payload }: { uuid: string; payload: StorageUpdateStorageUploadRequest }) => storageClient.storageUpdateUpload(uuid, payload),
);

export const storageFinishUpload = createAsyncThunk('storage/storageFinishUpload', async (uuid: string) => storageClient.storageFinishUpload(uuid));
export const storageGetUpload = createAsyncThunk('storage/storageGetUpload', async (uuid: string) => storageClient.storageGetUpload(uuid));
export const storageDeleteUpload = createAsyncThunk('storage/storageDeleteUpload', async (uuid: string) => storageClient.storageDeleteUpload(uuid));

export const storageSlice = createSlice({
  name: 'storage',
  initialState: {
    storageList: storageListAdapter.getInitialState(),
  } as StorageState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(storageListUpload.pending, (state: StorageState) => {
      state.error = undefined;
      state.fetching = { ...(state.fetching || {}), storageList: true };
    });
    builder.addCase(storageListUpload.fulfilled, (state: StorageState, action: AnyAction) => {
      state.fetching = { ...state.fetching, storageList: false };
      if (action.payload.data.result) {
        storageListAdapter.upsertOne(state.storageList, { id: keysToId(action.meta.arg), ...action.payload.data });
      }
    });
    builder.addCase(storageListUpload.rejected, (state: StorageState, action: AnyAction) => {
      state.error = action.error;
      state.fetching = { ...state.fetching, storageList: false };
    });
  },
});

export const { selectById: storageListUploadById } = storageListAdapter.getSelectors((state: RootState) => state.storage.storageList);

export const storageListUploadFetching = (state: RootState): boolean => state.storage.fetching?.storageList !== false;

const getWarehousesListSelector = createSelector([storageListUploadById], (items) => ({
  pagination: getPagination(items as ListResponseType),
  result: items?.result,
}));

export const getStorageListUploadById =
  (keys: StorageListUploadQuery) =>
  (state: RootState): StorageListUploadResponseType =>
    getWarehousesListSelector(state, keysToId(keys));

export default storageSlice.reducer;
