import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Box, Button, ClickAwayListener, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { imageBase } from '../../api/client';
import useMobileDetect from '../../hooks/useMobileDetect';
import useSearchParams from '../../hooks/useSearchParams';
import { QueryVehicleDashboardSummaryMapped } from '../../page/dashboard/interface';
import { getAllVehicles, getVehiclesDashboardFetching } from '../../redux/slices/dashboard/dashboardSlice';
import { isAdminUserSelector } from '../../redux/slices/users/usersSlice';
import { CardStyled, ColorValue } from '../../styled/components';
import ActiveSwitch from '../filters/ActiveSwitch';
import LocationFilterVehicle from '../filters/LocationFilterVehicle';
import SearchFilter from '../filters/SearchFilter';
import Table from '../table/Table';
import { Tooltip } from '../tooltip/Tooltip';
import { VehicleForm } from './VehicleForm';

export type FleetVehiclesProps = {
  withFilters?: boolean;
  collapsable?: boolean;
  title?: string;
  withNew?: boolean;
  headerAddon?: ReactElement;
};

export const FleetVehicles: React.FC<FleetVehiclesProps> = ({ withFilters = false, collapsable, title, withNew = false, headerAddon }: FleetVehiclesProps) => {
  const navigate = useNavigate();
  const [, setURLParams] = useSearchParams();
  const vehicles = useSelector(getAllVehicles);
  const vehiclesDashboardFetching = useSelector(getVehiclesDashboardFetching);
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
  const isMd = useMediaQuery(theme.breakpoints.only('md'), { noSsr: true });
  const [showAddVehicle, setShowAddVehicle] = useState(false);
  const [showTooltip, setShowTooltip] = useState('');
  const isMobile = useMobileDetect().isMobile();
  const isAdminUser = useSelector(isAdminUserSelector);

  const columns = useMemo(
    () => [
      ...(isSmUp
        ? [
            {
              accessor: 'image_key',
              Header: 'Image',
              Cell: ({ row }: { row: { original: QueryVehicleDashboardSummaryMapped } }) => (
                <Link to={`${row.original.warehouse_type === 'Site' ? '/company/rooms' : '/company/vehicles'}/${row.original.vehicle_id}/details`}>
                  {row.original.primary_vehicle_image && (
                    <img
                      src={`${imageBase(row.original.primary_vehicle_image)}?height=${isLgUp ? 36 : 25}`}
                      key={row.original.vehicle_id}
                      alt={row.original.vehicle_name}
                    />
                  )}
                </Link>
              ),
              disableSortBy: true,
              width: 50,
              style: { paddingTop: 2, paddingBottom: 2, textAlign: 'center' },
            },
          ]
        : []),
      {
        Header: 'Name',
        accessor: 'vehicle_name',
        width: 100,
        Cell: ({ row }: { row: { original: QueryVehicleDashboardSummaryMapped } }) => {
          return (
            <Link to={`${row.original.warehouse_type === 'Site' ? '/company/rooms' : '/company/vehicles'}/${row.original.vehicle_id}/details`}>
              {row.original.vehicle_name}
            </Link>
          );
        },
        ...(withFilters && { Filter: ActiveSwitch }),
      },

      {
        Header: 'As Of',
        accessor: 'processedAtMapped',
        Cell: ({ row }: { row: { original: QueryVehicleDashboardSummaryMapped } }) => (
          <Link to={`/payloads/${row.original.last_payload_id}/details`}>{row.original.processedAtMapped}</Link>
        ),
        ...(withFilters && { Filter: LocationFilterVehicle }),
      },
      {
        Header: 'Location',
        accessor: 'location_type_mapped',
        Cell: ({ row }: { row: { original: QueryVehicleDashboardSummaryMapped } }) =>
          row.original.location_type === 'On Road' ? (
            row.original.location_type_mapped
          ) : (
            <Link to={`/customers/job-sites/${row.original.site_id}/details`}>{row.original.location_type_mapped}</Link>
          ),
        ellipsis: true,
        width: '25%',
        ...(withFilters && { Filter: SearchFilter, filterOrder: -1 }),
      },
      {
        Header: 'Status',
        accessor: 'total_cnt_reads',
        disableSortBy: true,
        style: {
          textAlign: 'center',
        },
        Cell: ({ row }: { row: { id: string; original: QueryVehicleDashboardSummaryMapped } }) => (
          <ClickAwayListener onClickAway={() => setShowTooltip('')}>
            <div>
              {row.original.warehouse_type === 'Tool Room' || row.original.location_type === 'At Home' ? (
                <Tooltip
                  {...(isMobile && {
                    open: showTooltip === row.id,
                    PopperProps: {
                      disablePortal: true,
                    },
                  })}
                  title={
                    <Box sx={{ textAlign: 'left' }}>
                      <Typography variant="h6" marginBottom={2}>
                        Tool Status
                      </Typography>
                      {row.original.total_cnt_reads}
                      <ArrowRightAltIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />
                      "Scanned"
                    </Box>
                  }
                  arrow
                  placement="top"
                >
                  <span {...(isMobile && { onClick: () => setShowTooltip(row.id) })}>{row.original.total_cnt_reads}</span>
                </Tooltip>
              ) : (
                <Tooltip
                  {...(isMobile && {
                    open: showTooltip === row.id,
                    PopperProps: {
                      disablePortal: true,
                    },
                  })}
                  title={
                    <Box sx={{ textAlign: 'left' }}>
                      <Typography variant="h6" marginBottom={2}>
                        Tool Status
                      </Typography>
                      <Box>
                        <ColorValue color="black">{row.original.tracked_mapped}</ColorValue>
                        <ArrowRightAltIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />
                        "Tracked"
                      </Box>
                      <Box>
                        <ColorValue color={row.original.use_mapped ? 'blue' : 'black'}>{row.original.use_mapped}</ColorValue>
                        <ArrowRightAltIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />
                        "In Use"
                      </Box>
                      <Box>
                        <ColorValue color={row.original.leftBehind_mapped ? 'red' : 'black'}>{row.original.leftBehind_mapped}</ColorValue>
                        <ArrowRightAltIcon fontSize="small" sx={{ verticalAlign: 'middle' }} />
                        "Left Behind"
                      </Box>
                    </Box>
                  }
                  arrow
                  placement="top"
                >
                  <span {...(isMobile && { onClick: () => setShowTooltip(row.id) })}>
                    <ColorValue color="black">{row.original.tracked_mapped}</ColorValue> -{' '}
                    <ColorValue color={row.original.use_mapped ? 'blue' : 'black'}>{row.original.use_mapped}</ColorValue> -{' '}
                    <ColorValue color={row.original.leftBehind_mapped ? 'red' : 'black'}>{row.original.leftBehind_mapped}</ColorValue>
                  </span>
                </Tooltip>
              )}
            </div>
          </ClickAwayListener>
        ),
        ellipsis: isSmUp && !isMd,
        width: isSmUp ? '35%' : 'auto',
      },
    ],
    [withFilters, isSmUp, isMd, isLgUp, showTooltip, isMobile],
  );

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  const handleCloseAddVehicle = (redirect?: string) => {
    setShowAddVehicle(false);
    if (redirect) {
      navigate(redirect);
    }
  };

  return (
    <>
      <CardStyled sx={{ height: '100%' }}>
        <Table
          columns={columns}
          data={vehiclesDashboardFetching ? [] : vehicles}
          title={title}
          loading={vehiclesDashboardFetching}
          withPagination={withFilters}
          onFiltersClear={handleFiltersClear}
          showDefaultFilters={withFilters}
          collapsable={collapsable}
          noGlobalFilter
          toolbarDense="medium"
          size="small"
          headerAddon={
            withNew && isAdminUser ? (
              <Button color="primary" variant="contained" onClick={() => setShowAddVehicle(true)} disabled={vehiclesDashboardFetching}>
                New
              </Button>
            ) : (
              headerAddon
            )
          }
        />
      </CardStyled>
      {isAdminUser && <VehicleForm open={showAddVehicle} onClose={handleCloseAddVehicle} />}
    </>
  );
};
