import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Card, CardContent, CardHeader, CardMedia, Grid, IconButton, Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { customerClient, imageBase } from '../../api/client';
import { CardHeaderFlex } from '../../component/cardHeaderFlex/CardHeaderFlex';
import { CustomerForm } from '../../component/customer/CustomerForm';
import { LocationsList } from '../../component/customer/LocationsList';
import DependenciesButton from '../../component/dependenciesButton/DependenciesButton';
import { InfoPopup } from '../../component/infoPopup/InfoPopup';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import ListTypography from '../../component/listTypography/ListTypography';
import Skeleton from '../../component/skeleton/Skeleton';
import TabPanel from '../../component/tabPanel/TabPanel';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import {
  getCustomerByUuid,
  getCustomerDependencies,
  getCustomerDependenciesById,
  getCustomerDependenciesFetching,
  getCustomerDetailsById,
  getCustomerDetailsFetching,
  removeCustomer,
  updateCustomer,
} from '../../redux/slices/customer/customerSlice';
import { getSitesById, listSite } from '../../redux/slices/sites/sitesSlice';
import { getUserAbilities, getUserData } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { CardFlex, CardHeaderTabs, GridCells, MediaHolder } from '../../styled/components';

export const Customer: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, tab = 0 } = useParams();
  const fetching = useSelector(getCustomerDetailsFetching);
  const customer = useSelector(getCustomerDetailsById(id));
  const userData = useSelector(getUserData);
  const dependencies = useSelector(getCustomerDependenciesById(id));
  const fetchingDependencies = useSelector(getCustomerDependenciesFetching);
  const sites = useSelector(getSitesById({ siteOwner: 'customer', customerUuid: id }));
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: sites?.pagination });
  const [deleting, setDeleting] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [popupOpen, setPopupOpen] = useState('');
  const tabValue = Number(tab);
  const abilities = useSelector(getUserAbilities);
  const [, , clearParams] = useSearchParams();

  const handleCloseAddCustomer = useCallback(() => {
    if (id) {
      dispatch(getCustomerByUuid(id));
    }
    setShowAddCustomer(false);
  }, [dispatch, id]);

  const handleEditClick = () => setShowAddCustomer(true);

  const handleChangeTab = (event, newValue) => {
    navigate(`/customers/customers/${id}/details/${newValue}${clearParams}`);
  };

  useEffect(() => {
    if (id) {
      dispatch(listSite({ siteOwner: 'customer', customerUuid: id, ...paginationToGet }));
    }
  }, [dispatch, id, paginationToGet]);

  useEffect(() => {
    if (id) {
      dispatch(getCustomerByUuid(id));
      if (abilities.can('edit')) {
        dispatch(getCustomerDependencies(id));
      }
    }
  }, [dispatch, id, abilities]);

  const handleDelete = async () => {
    setDeleting(true);
    try {
      if (dependencies?.connected_record_count && dependencies?.connected_record_count > 0) {
        const payload = {
          id: customer?.id,
          organization_id: userData?.organization_id,
          name: customer?.name,
          active: false,
        };

        await dispatch(updateCustomer(payload)).unwrap();
      } else if (customer?.id) {
        await customerClient.deleteCustomer(customer.id);
        dispatch(removeCustomer(customer.id));
      }
      navigate('/customers/customers');
    } catch (error) {
      setDeleting(false);
      console.log(error); // eslint-disable-line
    }
  };

  const disableDelete = fetching || fetchingDependencies;

  const detailsData = [
    {
      label: 'Status',
      value: customer?.active ? 'Active' : 'Disabled',
    },
    {
      label: 'Created',
      value: customer?.created_at ? DateTime.fromISO(customer?.created_at).toLocaleString(DateTime.DATE_MED) : '',
    },
  ];

  return (
    <>
      {fetching && <LinearProgress />}
      <GridCells container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6}>
          <CardFlex>
            <CardHeaderFlex title={<Skeleton randomWidth fetching={fetching} value={customer?.name || <span>&nbsp;</span>} width={100} />} legend="Details" />
            <CardHeader
              title={<ListTypography data={detailsData} fetching={fetching} />}
              action={
                <Grid container display="flex" justifyContent="flex-end">
                  {abilities.can('CRUD') && (
                    <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end" sx={{ pb: 2 }}>
                      <Button onClick={handleEditClick} variant="contained" color="primary" disabled={fetching} style={{ minWidth: 80 }}>
                        Edit
                      </Button>
                    </Grid>
                  )}
                  {abilities.can('CRUD') && (
                    <Grid item xs={12} md="auto" display="flex" justifyContent="flex-end">
                      <DependenciesButton
                        disableDelete={disableDelete}
                        dependencies={dependencies?.connected_record_count}
                        active={customer?.active}
                        deleting={deleting}
                        name={customer?.name}
                        handleDelete={handleDelete}
                      />
                    </Grid>
                  )}
                </Grid>
              }
            />
          </CardFlex>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardFlex style={{ display: 'flex', flexDirection: 'column' }}>
            <CardHeaderFlex title="Customer" legend="Image" />
            <MediaHolder className={clsx({ icon: !customer?.image_key })} sx={{ p: 4 }}>
              {customer?.image_key ? (
                <CardMedia component="img" image={imageBase(customer?.image_key)} alt={customer?.name} style={{ objectFit: 'contain' }} />
              ) : (
                <ImageOutlinedIcon sx={{ fontSize: '72px', opacity: 0.1 }} />
              )}
            </MediaHolder>
          </CardFlex>
        </Grid>
      </GridCells>
      <Card>
        <CardHeaderTabs
          title={
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleChangeTab} variant="scrollable" scrollButtons="auto">
                <Tab label="Locations" />
              </Tabs>
            </Box>
          }
          action={
            <IconButton onClick={() => setPopupOpen('tabs')}>
              <InfoOutlinedIcon fontSize="small" color="secondary" />
            </IconButton>
          }
          sx={{
            '& .MuiCardHeader-content': {
              width: 0,
            },
          }}
        />
        <CardContent sx={{ p: 0 }}>
          <TabPanel value={tabValue} index={0}>
            <LocationsList sites={sites} paginationControl={paginationControl} pagination={pagination} />
          </TabPanel>
        </CardContent>
      </Card>

      <InfoPopup title="Tab Details" subtitle="Displays the list of Job Sites for the Customer." onClose={() => setPopupOpen('')} open={popupOpen === 'tabs'}>
        <Card sx={{ mb: 3, p: 3 }}>
          <CardHeader title={<strong>Locations</strong>} sx={{ p: 0 }} />
          <CardContent sx={{ p: 0, pt: 2 }} style={{ paddingBottom: 0 }}>
            List of Locations (Job Sites) belonging to the customer.
          </CardContent>
        </Card>
      </InfoPopup>
      <CustomerForm open={showAddCustomer} onClose={handleCloseAddCustomer} customerID={id} />
    </>
  );
};
