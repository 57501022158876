import { DateTime } from 'luxon';
import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { QueryUser } from '../../api/schema';
import ActiveSwitch from '../../component/filters/ActiveSwitch';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import PopupMenu from '../../component/popupMenu/PopupMenu';
import Table from '../../component/table/Table';
import { formatPhoneNumber } from '../../helpers/dataHelper';
import toastOptions from '../../helpers/toastOptions';
import useCurrentRoute from '../../hooks/useCurrentRoute';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import { allAdminUsersFetching, getAllAdminUsers, listAdminUsers } from '../../redux/slices/admin/adminSlice';
import { reInviteUser } from '../../redux/slices/users/usersSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const AdminUsersList = ({ organizationId }: { organizationId?: string }): ReactElement => {
  const dispatch = useDispatch();
  const route = useCurrentRoute();
  const [params, setURLParams] = useSearchParams();

  const paramsToUse = useMemo(
    () => ({
      ...(organizationId && { organizationId }),
      ...(params.search && { search: params.search }),
      active: !params.active ? 'true' : 'false',
    }),
    [params, organizationId],
  );
  const userItems = useSelector(getAllAdminUsers(paramsToUse));
  const [pagination, paginationToGet, paginationControl, paginationToGetAll] = useTablePagination({ paginationDirty: userItems.pagination });
  const userItemsExport = useSelector(getAllAdminUsers({ ...paramsToUse, ...paginationToGetAll }));
  const fetching = useSelector(allAdminUsersFetching);

  useEffect(() => {
    dispatch(
      listAdminUsers({
        ...paramsToUse,
        ...paginationToGet,
      }),
    );
  }, [dispatch, paginationToGet, paramsToUse]);

  const getExportData = useCallback(async () => {
    await dispatch(
      listAdminUsers({
        ...paramsToUse,
        ...paginationToGetAll,
      }),
    );
  }, [dispatch, paramsToUse, paginationToGetAll]);

  const reInvite = useCallback(
    async (event) => {
      event.preventDefault();
      const { dataset } = event.currentTarget;
      const ids = dataset.id?.split('||');
      if (ids?.[0] && ids?.[1]) {
        try {
          await dispatch(
            reInviteUser({
              organization_id: ids[1],
              user_id: ids[0],
            }),
          );
          toast.success('User was successfully invited', toastOptions);
        } catch (error) {
          console.log(error); // eslint-disable-line
        }
      }
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        Header: 'Organization',
        accessor: 'organization.name',
        disableSortBy: true,
        Filter: ActiveSwitch,
      },
      {
        Header: 'Login',
        accessor: 'loginMapped',
        sortBy: 'email',
        Cell: ({ row, value }: { row: { original: QueryUser }; value: string }) => (
          <Link to={`/admin/organizations/users/${row.original.id}/details`}>{value}</Link>
        ),
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        Cell: ({ value }: { value?: string }) => formatPhoneNumber(value),
      },
      {
        Header: 'Full Name',
        accessor: 'nameMapped',
        sortBy: 'first_name',
      },
      {
        Header: 'Roles',
        accessor: 'roles',
      },
      {
        Header: 'Status',
        accessor: 'activeMapped',
        sortBy: 'active',
      },
      {
        Header: 'Verified',
        accessor: 'verifiedMapped',
        sortBy: 'verified',
      },
      {
        Header: 'Last Login',
        accessor: 'last_login',
        Cell: ({ value }: { value: string | null }) => (value ? DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED) : value),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }: { row: { original: QueryUser } }) => (
          <PopupMenu
            onClick={reInvite}
            menuItems={[
              {
                alias: 'reinvite',
                children: 'Reinvite',
                'data-id': `${row.original.id}||${row.original.organization_id}`,
                disabled: !!row.original.email_invite_verified,
              },
            ]}
          />
        ),
        width: 50,
        disableSortBy: true,
      },
    ],
    [reInvite],
  );

  const handleFiltersClear = useCallback(() => {
    setURLParams({ clearAll: true });
  }, [setURLParams]);

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={userItems.result}
          dataExport={userItemsExport?.result}
          loading={fetching}
          paginationControl={paginationControl}
          pagination={pagination}
          onFiltersClear={handleFiltersClear}
          exportFileName={route?.route?.path}
          getExportData={getExportData}
        />
      </Card>
    </>
  );
};
