import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button, CircularProgress } from '@mui/material';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { QueryStorageUpload } from '../../api/schema';
import { ConfirmDialog } from '../../component/confirmDialog/ConfirmDialog';
import { UploadForm } from '../../component/downloads/UploadForm';
import PdfIcon from '../../component/icons/Pdf';
import LinearProgress from '../../component/linearProgress/LinearProgress';
import PopupMenu from '../../component/popupMenu/PopupMenu';
import Table from '../../component/table/Table';
import useBooleanKeys from '../../hooks/useBooleanKeys';
import useSearchParams from '../../hooks/useSearchParams';
import useTablePagination from '../../hooks/useTablePagination';
import {
  getStorageListUploadById,
  storageDeleteUpload,
  storageGetUpload,
  storageListUpload,
  storageListUploadFetching,
} from '../../redux/slices/storage/storageSlice';
import { useDispatch } from '../../redux/store';
import { Card } from '../../styled/components';

export const Documents: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [params] = useSearchParams();
  const downloadItemsRef = useRef<{ [key: string]: boolean }>({});
  const [downloadItems, setDownloadItems] = useState<{ [key: string]: boolean }>({});
  const [modals, toggleModal] = useBooleanKeys({ showAddDocument: false, showDeleteDocument: false });
  const [selectedDocument, setSelectedDocument] = useState<QueryStorageUpload>();
  const [deleting, setDeleting] = useState(false);

  downloadItemsRef.current = downloadItems;

  const paramsToUse = useMemo(() => {
    return {
      ...params,
      ownerId: id,
    };
  }, [params, id]);

  const documents = useSelector(getStorageListUploadById(paramsToUse));
  const [pagination, paginationToGet, paginationControl] = useTablePagination({ paginationDirty: documents.pagination });

  const fetching = useSelector(storageListUploadFetching);

  useEffect(() => {
    dispatch(storageListUpload({ ...paramsToUse, ...paginationToGet }));
  }, [dispatch, paramsToUse, paginationToGet]);

  const handleDocument = useCallback(
    async (event: SyntheticEvent, alias: string) => {
      event.preventDefault();
      const { dataset } = event.currentTarget as HTMLInputElement;
      const actionByAlias = {
        download: async (id: string) => {
          try {
            setDownloadItems((prevState) => ({ ...prevState, [id]: true }));
            const {
              data: { result },
            } = await dispatch(storageGetUpload(id)).unwrap();

            if (result?.download_url && result?.file_key) {
              const blob = await fetch(result?.download_url).then((result) => result.blob());
              saveAs(blob, result?.file_key.split('/').reverse()[0]);
            }
          } catch (error) {
            console.log(error); // eslint-disable-line
          } finally {
            setDownloadItems((prevState) => ({ ...prevState, [id]: false }));
          }
        },
        edit: (id: string) => {
          const targetDocument = documents?.result?.find((document) => document.id === id);
          if (targetDocument) {
            setSelectedDocument(targetDocument);
            toggleModal('showAddDocument');
          }
        },
        delete: (id: string) => {
          const targetDocument = documents?.result?.find((document) => document.id === id);
          if (targetDocument) {
            setSelectedDocument(targetDocument);
            toggleModal('showDeleteDocument');
          }
        },
      };
      if (dataset.id) {
        actionByAlias[alias]?.(dataset.id);
      }
    },
    [dispatch, documents, toggleModal],
  );

  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'type',
        width: 50,
        Cell: ({ row }: { row: { original: QueryStorageUpload } }) =>
          row.original.id && downloadItemsRef.current[row.original.id] ? (
            <CircularProgress size={21} color="inherit" sx={{ mt: 1 }} />
          ) : (
            <PdfIcon color="error" data-id={row.original.id} onClick={(event) => handleDocument(event, 'download')} sx={{ cursor: 'pointer' }} />
          ),
      },
      {
        Header: 'Document',
        accessor: 'file_name',
      },
      {
        Header: 'Description',
        accessor: 'description',
        ellipsis: true,
      },
      {
        Header: 'Created',
        accessor: 'created_at',
        Cell: ({ value }: { value: string }) => DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }: { row: { original: QueryStorageUpload } }) => (
          <PopupMenu
            onClick={handleDocument}
            menuItems={[
              {
                alias: 'download',
                icon:
                  row.original.id && downloadItemsRef.current[row.original.id] ? (
                    <CircularProgress size={18} color="inherit" />
                  ) : (
                    <FileDownloadOutlinedIcon fontSize="small" />
                  ),
                children: 'Download',
                'data-id': row.original.id,
                disabled: !!(row.original.id && downloadItemsRef.current[row.original.id]),
              },
              {
                alias: 'edit',
                icon: <EditIcon fontSize="small" />,
                children: 'Edit',
                'data-id': row.original.id,
              },
              {
                alias: 'delete',
                icon: <DeleteIcon fontSize="small" />,
                children: 'Delete',
                'data-id': row.original.id,
              },
            ]}
          />
        ),
        width: 50,
        disableSortBy: true,
      },
    ],
    [handleDocument],
  );

  const handleOnClose = useCallback(
    (update) => {
      if (update) {
        dispatch(storageListUpload({ ...paramsToUse, ...paginationToGet }));
      }
      toggleModal('showAddDocument');
      setSelectedDocument(undefined);
    },
    [dispatch, paramsToUse, paginationToGet, toggleModal],
  );

  const handleDeleteDocument = useCallback(async () => {
    setDeleting(true);
    if (selectedDocument?.id) {
      try {
        await dispatch(storageDeleteUpload(selectedDocument?.id));
        toggleModal('showDeleteDocument');
        setSelectedDocument(undefined);
        dispatch(storageListUpload({ ...paramsToUse, ...paginationToGet }));
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      } finally {
        setDeleting(false);
      }
    }
  }, [dispatch, paramsToUse, paginationToGet, toggleModal, selectedDocument]);

  return (
    <>
      {fetching && <LinearProgress />}
      <Card>
        <Table
          columns={columns}
          data={documents?.result}
          loading={fetching}
          paginationControl={paginationControl}
          pagination={pagination}
          size="small"
          headerAddon={
            <Button color="primary" variant="contained" onClick={() => toggleModal('showAddDocument')} disabled={fetching}>
              New
            </Button>
          }
        />
        <UploadForm open={modals.showAddDocument} onClose={handleOnClose} document={selectedDocument} />
        <ConfirmDialog
          open={modals.showDeleteDocument}
          onClose={() => toggleModal('showDeleteDocument')}
          title="Are you sure you want to delete this document?"
          onConfirm={handleDeleteDocument}
          confirmLabel="Delete"
          sending={deleting}
        >
          {selectedDocument?.file_name}
        </ConfirmDialog>
      </Card>
    </>
  );
};
